@import "../../styles/partials/colors";

.button_container {
  position: relative;
  background-color: $black;
  border: 0;
  border-radius: 2rem;
  cursor: pointer;
  font-weight: 500;
  padding: 0.7rem 1.8rem;
  transition: all 0.3s ease;
  width: max-content;

  &:hover {
    box-shadow: 0px 2px 16px $shadow-grey;
  }

  &:disabled {
    box-shadow: inset 0 0 0 transparent;
    cursor: default;
    opacity: 0.7;
  }

  .centered {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (max-width: 575px) {
  .button_container {
    margin-top: 1.3rem;
    padding: 0.85rem 1.9rem;
  }
}
