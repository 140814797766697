@import "../../styles/partials/colors";

.footer_container {
  background-color: $footer-background-grey;
  bottom: 0;
  overflow-x: hidden;
  margin-bottom: 0;
  margin-top: 0;
  padding-bottom: 3rem;
  padding-top: 4.5rem;

  .branding_container {
    .logo {
      cursor: pointer;
      margin-bottom: 1.5rem;
      max-width: 30%;
    }

    p {
      color: $footer-grey;
      max-width: 90%;
    }

    .footer_copyright {
      margin-top: 3.5rem;
    }
  }

  .footer_links {
    .main_link :hover {
      color: rgba(255, 255, 255, 0.866);
    }
    align-items: flex-start;

    .links_section {
      display: flex;
      flex-direction: column;
    }

    .heading {
      color: $footer-grey;
      font-weight: 500;
      padding: 0.5rem;
      font-size: 20px;
      padding-left: 0;
      white-space: nowrap;
    }

    .footer_link {
      margin: 0.5rem 0 0.5rem;

      a,
      .footer_link_text {
        color: $footer-grey;
        text-decoration: none;
      }
    }
  }

  .social_container {
    padding-left: 0;

    .social_handles {
      margin-bottom: 3rem;
      margin-top: 3rem;

      .handle:first-child {
        margin: 0.6rem 1rem 0.6rem 0;
      }
      .handle {
        margin: 0.6rem 1rem 0.6rem 1rem;
      }
    }
  }
}

.footer_container_dark {
  background-color: $background-dark-grey;

  .branding_container {
    p {
      color: $text-light-grey;
    }
  }

  .footer_links {
    .heading {
      color: $white;
    }

    .footer_link {
      a,
      .footer_link_text {
        color: $text-light-grey;
      }
    }
  }
}

@media (max-width: 991px) {
  .footer_container {
    .branding_container {
      order: 2;

      .logo {
        max-width: 20%;
      }
    }

    .footer_links_container {
      order: 1;
    }
    .social_container {
      .social_handles {
        .handle:first-child {
          margin-right: 0.2rem;
        }
        .handle {
          margin: 0 0.6rem;
        }
      }
    }
  }
}

@media (max-width: 575px) {
  .footer_container {
    .branding_container {
      margin-bottom: -2rem;
      order: 2;

      .logo {
        max-width: 30%;
      }
    }

    .links_section {
      padding-bottom: 1rem;

      .heading {
        font-size: 1.4rem;
      }
    }
  }
}
