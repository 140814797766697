@import "/src/styles/partials/colors";

.header_outer_container {
  background: transparent;
  left: 0;
  margin: 0;
  padding: 0;
  position: fixed;
  top: 0;
  transition: all 0.4s ease;
  width: 100vw;
  z-index: 1000;

  .logo {
    height: 60px;
    width: auto;
  }
}

.header_container {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 2.5rem 8% 1.5rem;
}

.box_shadow {
  box-shadow: 0 5px 10px $shadow-hover-grey;
}

.white_background {
  background-color: $white;

  .navlink a {
    color: $background-dark-grey;
  }
}

.header_controller {
  cursor: pointer;
  display: inline-block;
  height: 16px;
  position: relative;
  transform: scale(1.2);
  width: 23px;

  span {
    display: block;
    height: 2px;
    opacity: 0.7;
    position: absolute;
    transform: rotate(0deg);
    transition: 0.25s ease-in-out;
    width: 100%;

    &:nth-child(1) {
      top: 0;
    }

    &:nth-child(2) {
      top: 7px;
    }

    &:nth-child(3) {
      top: 14px;
    }
  }
}

.header_open_controller {
  span {
    &:nth-child(1) {
      width: 0;
    }

    &:nth-child(2) {
      left: 0;
      top: 0;
      transform: rotate(45deg);
      transform-origin: top left;
      width: 30px;
    }

    &:nth-child(3) {
      left: 0;
      top: 21px;
      transform: rotate(-45deg);
      transform-origin: bottom left;
      width: 30px;
    }
  }
}

.header_body {
  animation: fadeIn 0.3s ease-in;
  background: $white;
  padding: 2rem 8%;
  position: relative;

  ul {
    font-weight: 500;

    li {
      border-bottom: 0.5px solid $shadow-hover-grey;
      padding: 0.8rem 0;

      &::before {
        content: none;
      }

      a {
        color: $heading-grey;
      }
    }
  }
}

.navlink_active {
  color: $primary-orange !important;
  font-weight: 600 !important;
}

.header_bubble {
  background-color: $success-green;
  border-radius: 0.3rem;
  color: $white;
  margin-left: 0.75rem;
  padding: 0.3rem 0.5rem;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media (max-width: 1399px) {
  .header_container {
    padding: 2rem 4% 1.5rem;
  }

  .header_body {
    padding: 2rem 4%;
  }
}

@media (max-width: 991px) {
  .header_container {
    padding: 2rem 5% 1.5rem;
  }

  .header_body {
    padding: 2rem 3%;
  }
}

@media (max-width: 575px) {
  .header_outer_container {
    .logo {
      height: 50px;
    }
  }

  .header_container {
    padding: 2rem 20px 1.5rem;
  }

  .header_controller {
    transform: scale(0.9);
  }

  .header_body {
    padding: 2rem 4%;
  }
}
