@import "../../../styles/partials/colors";

.header_outer_container {
  background: transparent;
  left: 0;
  margin: 0;
  padding: 2.5rem 7% 2rem;
  position: fixed;
  top: 0;
  transition: all 0.4s ease;
  width: 100vw;
  z-index: 998;

  .logo {
    height: 70px;
    width: auto;
  }
}

.header_container {
  align-items: center;
  display: flex;
  justify-content: space-between;

  .navlinks_container {
    align-items: center;
    display: flex;
  }
}

.box_shadow {
  box-shadow: 0 5px 10px $shadow-hover-grey;
}

.white_background {
  background-color: $white;

  .navlink a {
    color: $background-dark-grey;
  }
}

.navlink {
  position: relative;
  margin-right: 2.5rem;

  a {
    color: $white;
    font-weight: 500;
    transition: all 0.3s ease;
    white-space: nowrap;

    &:hover {
      color: $primary-orange;
    }
  }
}

.navlink_active {
  color: $primary-orange !important;
  font-weight: 600 !important;
}

.header_bubble {
  background-color: $scroll-bar-grey;
  border-radius: 0.6rem;
  color: $white;
  left: 50%;
  padding: 0.3rem 0.5rem;
  position: absolute;
  top: 1.8rem;
  transform: translateX(-50%) scale(0.8);
  white-space: nowrap;
}

@media (max-width: 1399px) {
  .header_outer_container {
    padding: 2.5rem 4% 2rem;
  }
}

@media (max-width: 991px) {
  .header_outer_container {
    padding: 2.5rem 5% 2rem;
  }
}
