@import "../../styles/partials/colors";

.announcement_container {
  align-items: center;
  background-color: $background-dark-grey;
  display: flex;
  justify-content: center;
  left: 0;
  margin: 0;
  max-height: 100px;
  min-height: 60px;
  overflow: hidden;
  padding: 0 20px;
  position: fixed;
  right: 0;
  text-align: center;
  transition: all 0.4s ease;
  top: 0;
  width: 100%;
  z-index: 999;

  h6 {
    color: $text-bright-grey;
    display: inline;
    font-size: 1rem;
    line-height: 1.3rem;
    padding-top: 8px;
  }
}

.mobile_container {
  border-radius: 0.6rem;
  margin-left: auto;
  margin-right: auto;
  max-height: 75px;
  min-height: 65px;
  padding: 0.2rem 0.5rem;
  top: 105px;
  transition: all 0.4s ease;
  width: 95%;
}

.emoji {
  height: 1.2rem;
  margin: 0 0.3rem;
  width: 1.2rem;
}

.hidden {
  top: -12rem !important;
}

@media (max-width: 1199px) {
  .announcement_container {
    min-height: 96px;
    text-align: left;

    h6 {
      font-size: 1.2rem;
      line-height: 1.7rem;
    }
  }
}
